*{
  margin: 0;
}

body {
  font-size: 22px;
}

h2 {
  font-size: 18px
}

h3 {
  font-size: 15px;
  color: rgb(111, 6, 6);
}

#drum-machine {
  margin: 15px;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.wrapper {
    width: 100%;
    display: grid;
    padding: 10px;
    grid-gap: 15px;
    margin-top: 40px;
    border-radius: 20px;
    grid-template-columns: 1fr;
    background-image: linear-gradient(
-225deg
, #69d55d 45%, #b9ffde 100%);
}

.keyboard {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}

.keyboard .drum-pad {
  width: 100%;
  height: 50px;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50px;
  background-color: white;
  transition: all .3s ease-in-out
}

@media(min-width: 768px){
  .wrapper {
      width: 420px;
  }
}

@media(min-width: 992px){
  .wrapper {
     grid-template-columns: 1fr 1fr;
  }
}

.controle {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.controle > button,
.controle > input,
.controle > h2{
  margin-bottom: 10px
}

.controle button{
  border: none;
  color: #e27676;
  cursor: pointer;
  font-size: 15px;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: #000000;
}